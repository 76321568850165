import React, { useContext, useEffect } from "react";
import styles from "./Sidebar.module.css";
import { NavLink, useLocation } from "react-router-dom";
import { ProductContext } from "App";
import routes from "Routes/routes.config";
import { ChevronLeftIcon } from "userlove-icons";

export const Sidebar = () => {
    const { showSidebar, setShowSidebar } = useContext(ProductContext);
    const location = useLocation();

    const userSidebarGroup = [...routes.private[0].children].slice(1);

    const settingsGroupItems = userSidebarGroup.splice(
        userSidebarGroup.findIndex((route) => route.break) + 1,
    );

    const MatchedPaths = [...userSidebarGroup, ...settingsGroupItems].find((item) => {
        if (item.path === location.pathname || location.pathname.includes(item.path)) {
            return item;
        } else if (
            item.children?.find(
                (subMenuItem) =>
                    subMenuItem.path === location.pathname ||
                    location.pathname.includes(subMenuItem.path),
            )
        ) {
            return item;
        } else {
            return null;
        }
    });

    const subMenu = MatchedPaths?.children?.slice(1) || null;

    useEffect(() => {
        if (!subMenu) {
            setShowSidebar(false);
        } else {
            setShowSidebar(true);
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    return (
        <div className={styles["gs-sidebar-container"]}>
            <div className={styles["gs-sidebar"]}>
                <div className={styles["gs-sidebar-left"]}>
                    <div>
                        {userSidebarGroup.map((data, i) =>
                            !data.break ? (
                                <NavLink
                                    key={i}
                                    to={data.path}
                                    onClick={() => subMenu && !showSidebar && setShowSidebar(true)}
                                    className={
                                        MatchedPaths?.path?.includes(data.path)
                                            ? `${styles["gs-sidebar-left-link"]} ${styles["active"]}`
                                            : styles["gs-sidebar-left-link"]
                                    }
                                >
                                    {data.icon}
                                    {data.text}
                                </NavLink>
                            ) : null,
                        )}
                    </div>
                    {/* <div>
                        {settingsGroupItems.map((data, i) =>
                            data.text === "Help" ? (
                                <div
                                    key={i}
                                    onClick={() => {
                                        window.open(data.path, "_blank");
                                    }}
                                    style={{ cursor: "pointer" }}
                                    className={
                                        MatchedPaths?.path?.includes(data.path)
                                            ? `${styles["gs-sidebar-left-link"]} ${styles["active"]}`
                                            : styles["gs-sidebar-left-link"]
                                    }
                                >
                                    {data.icon}
                                    {data.text}
                                </div>
                            ) : (
                                <NavLink
                                    onClick={() => subMenu && !showSidebar && setShowSidebar(true)}
                                    key={i}
                                    to={data.path}
                                    className={
                                        MatchedPaths?.path?.includes(data.path)
                                            ? `${styles["gs-sidebar-left-link"]} ${styles["active"]}`
                                            : styles["gs-sidebar-left-link"]
                                    }
                                >
                                    {data.icon}
                                    {data.text}
                                </NavLink>
                            ),
                        )}
                    </div> */}
                </div>
                {subMenu && (
                    <div
                        className={
                            showSidebar
                                ? styles["gs-sidebar-right"]
                                : `${styles["gs-sidebar-right"]} ${styles["hide"]}`
                        }
                    >
                        {subMenu?.map((subMenuItem, i) => (
                            <React.Fragment key={i}>
                                {subMenuItem.label ? (
                                    <>
                                        <div className={styles["gs-submenu-wrap"]}>
                                            <span className={styles["gs-submenu-label"]}>
                                                {subMenuItem.label}
                                            </span>
                                        </div>
                                    </>
                                ) : subMenuItem?.showSidebar === false ? null : (
                                    <>
                                        <NavLink
                                            to={subMenuItem.path}
                                            className={
                                                location.pathname === subMenuItem.path ||
                                                location.pathname.includes(subMenuItem.path)
                                                    ? `${styles["gs-sidebar-right-link"]} ${styles["active"]}`
                                                    : styles["gs-sidebar-right-link"]
                                            }
                                        >
                                            {subMenuItem.text}
                                        </NavLink>
                                        {subMenuItem.separator && (
                                            <div className={styles["gs-sidebar-separator"]}></div>
                                        )}
                                    </>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
            {location.pathname === "/users"
                ? null
                : subMenu && (
                    <span
                        className={
                            showSidebar
                                ? styles["gs-sidebar-button"]
                                : `${styles["gs-sidebar-button"]} ${styles["hide"]}`
                        }
                        onClick={() => setShowSidebar(!showSidebar)}
                    >
                        <ChevronLeftIcon width={12} height={12} className="secondary-icon" />
                    </span>
                )}
        </div>
    );
};
