import { useQuery } from "@apollo/client";
import React, { useContext, createContext } from "react";
import { GET_ALL_PAYMENTS } from "../graphql/payment";
import { TableData } from "shared/Components";
import { useState } from "react";
import useDateRange from "shared/Hooks/useDateRange";
import moment from "moment";
import { ProductContext } from "App";

const ctx = createContext();

export const usePayment = () => useContext(ctx);

//?---------------------------------- CUSTOM ORGNIZATION CELL	---------------------------------*/
const CustomOrgnizationCell = ({ row }) => {
    return (
        <TableData
            showImage={true}
            srcType="avatar"
            url={row?.logo}
            primaryText={row?.account_name || null}
            secondaryText={row?.email_id || null}
        ></TableData>
    );
};

//?---------------------------------- CUSTOM COUNTRY CELL ---------------------------------*/
const CustomCountryCell = ({ row }) => {
    return (
        <TableData
            showImage={true}
            srcType="image"
            url={row?.country_flag ? row?.country_flag : ""}
            primaryText={row?.country || null}
            secondaryText={row?.state_name || null}
        ></TableData>
    );
};

//?---------------------------------- CUSTOM TRANSACTION ID CELL ---------------------------------*/
const CustomTransactionCell = ({ row }) => {
    return row?.transaction_id;
};

//?---------------------------------- CUSTOM AMOUNT CELL ---------------------------------*/
const CustomAmountCell = ({ row }) => {
    <TableData primaryText={row?.amount || null}></TableData>;
};

//?---------------------------------- CUSTOM PAYMENT GATEWAY CELL ---------------------------------*/
const CustomPaymentGatewayCell = ({ row }) => {
    <TableData primaryText={row?.payment_method || null}></TableData>;
};
export const PaymentProvider = ({ children }) => {
    //?---------------------------------- STATE INITIALIZATION	---------------------------------*/
    const [page, setPage] = useState(0);
    const [sizePerPage, setSizePerPage] = useState(10);
    const { searchText } = useContext(ProductContext);
    /**
     * @param {number} newVal - Page number
     */
    const handlePageChange = (newVal) => {
        setPage(newVal);
    };

    /**
     * @param {number} newVal - Page number
     */
    const handlePageSizeChange = (newVal) => {
        setSizePerPage(newVal);
    };

    const rangePickerProps = useDateRange();

    //?---------------------------------- GET ALL PAYMENT  ---------------------------------*/
    const {
        loading: getPaymentLoading,
        data: { getTransactionHistory: { data: row = [], rowsCount: count = 0 } } = {
            getTransactionHistory: {},
        },
    } = useQuery(GET_ALL_PAYMENTS, {
        variables: {
            filter: rangePickerProps?.selected?.id || "custom",
            startDate: moment.utc(rangePickerProps?.start)?.toISOString() || null,
            endDate: moment.utc(rangePickerProps?.end)?.toISOString() || null,
            search: searchText,
            skip: page * sizePerPage,
            take: sizePerPage,
        },
    });

    //?---------------------------------- COLUMN INTIALIZATION FOR TABLE ---------------------------------*/
    const columns = [
        {
            field: "orgnization",
            flex: 1,
            minWidth: 300,
            renderCell: CustomOrgnizationCell,
        },
        {
            field: "country",
            flex: 1,
            minWidth: 180,
            renderCell: CustomCountryCell,
        },
        {
            field: "transaction_id",
            flex: 1,
            minWidth: 300,
            renderCell: CustomTransactionCell,
        },
        {
            field: "amount",
            flex: 1,
            minWidth: 160,
            renderCell: CustomAmountCell,
        },
        {
            field: "payment_method",
            flex: 1,
            minWidth: 160,
            renderCell: CustomPaymentGatewayCell,
        },
    ];

    return (
        <ctx.Provider
            value={{
                columns,
                row,
                getPaymentLoading,
                ...rangePickerProps,
                handlePageChange,
                page,
                count,
                handlePageSizeChange,
                sizePerPage,
                searchText,
            }}
        >
            {children}
        </ctx.Provider>
    );
};
