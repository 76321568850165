import React from "react";
import { useRef } from "react";
import { Box, MUITextField, IconButton } from "..";
import styles from "./PasswordField.module.css";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EyeIcon, EyeSlashIcon } from "userlove-icons";

export const PasswordField = ({
    fullWidth = true,
    placeholder,
    inValid = false,
    // valid = false,
    // value,
    label,
    // helperText,
    // rows = 4,
    size = "medium",
    require = false,
    name,
    onChange = null,
    ...rest
}) => {
    const inputRef = useRef();
    const { control } = useFormContext();
    const [visibilty, setVisibility] = useState(false);

    const handleLableClick = () => {
        inputRef.current.focus();
    };

    const inValidClassName = (invalid) => (require && invalid ? styles.error : "");
    const validClassName = (invalid, isDirty) =>
        require && !invalid && isDirty ? styles.success : "";
    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { ref: formInputRef, ...field },
                fieldState: { error, invalid, isDirty, isTouched },
            }) => (
                <Box
                    className={`${styles.wrapper} ${inValidClassName(
                        invalid,
                        isDirty,
                        error,
                        isTouched,
                    )} ${validClassName(invalid, isDirty, error, isTouched)}`}
                >
                    {label && (
                        <label onClick={handleLableClick} className={styles.label}>
                            {label + " "}{" "}
                            {require && <span style={{ color: "var(--danger-color)" }}>{"*"}</span>}
                        </label>
                    )}
                    <MUITextField
                        type={visibilty ? "text" : "password"}
                        className={styles["text-field"]}
                        name={name}
                        {...field}
                        inputRef={formInputRef}
                        error={inValid || invalid}
                        fullWidth={fullWidth}
                        placeholder={placeholder}
                        size={size}
                        onChange={(e) => (onChange ? onChange(e) : field.onChange(e))}
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setVisibility(!visibilty)} tabIndex={-1}>
                                    {visibilty ? (
                                        <EyeIcon fontSize={20} />
                                    ) : (
                                        <EyeSlashIcon fontSize={20} />
                                    )}
                                </IconButton>
                            ),
                        }}
                        {...rest}
                    />
                    {!!error && (
                        <div className={styles["helper-text"]}>
                            {/* <InfoOutlinedIcon /> */}
                            <p>{error?.message}</p>
                        </div>
                    )}
                </Box>
            )}
        />
    );
};
