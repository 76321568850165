import React from "react";
import styles from "./Drawer.module.css";
import {
    MUIDialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    IconButton,
} from "..";
import { XMarkIcon } from "userlove-icons";

const Title = ({ children, onClose, ...rest }) => {
    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...rest}>
            {children}
            {onClose ? (
                <Tooltip
                    classes={{ popper: "gs-tooltip" }}
                    arrow
                    title={"Close"}
                    placement="bottom"
                >
                    <IconButton className={styles["close-button"]} onClick={onClose}>
                        <XMarkIcon className="close-icon" />
                    </IconButton>
                </Tooltip>
            ) : null}
        </DialogTitle>
    );
};

const Content = ({ children, ...rest }) => {
    return (
        <DialogContent classes={{ root: styles["gs-modal-content"] }} {...rest}>
            {children}
        </DialogContent>
    );
};

const ContentText = ({ children, className, ...rest }) => {
    return (
        <DialogContentText
            className={className}
            //classes={{ root: styles["gs-modal-content"] }}
            {...rest}
        >
            {children}
        </DialogContentText>
    );
};

const Actions = ({ children, className, ...rest }) => {
    return (
        <DialogActions
            className={className}
            classes={{ root: styles["gs-modal-action-button"] }}
            {...rest}
        >
            {children}
        </DialogActions>
    );
};

export const Dialog = ({ open, children, onClose, ...rest }) => {
    return (
        <MUIDialog
            open={open}
            onClose={onClose}
            PaperProps={{ sx: { maxWidth: "500px " } }}
            fullWidth
            classes={{ root: styles["gs-modal"], paper: styles["gs-modal-paper"] }}
            {...rest}
        >
            {children}
        </MUIDialog>
    );
};

Dialog.Title = Title;
Dialog.Content = Content;
Dialog.ContentText = ContentText;
Dialog.Actions = Actions;
