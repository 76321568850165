import React, { useEffect, useRef, useState } from "react";
import styles from "./ContentWrapper.module.css";
import { Helmet } from "react-helmet";
import { Button, IconButton, LinearProgressLoader, SearchInput, Stack } from "..";
import { ButtonGroup, Popper } from "@mui/material";
import {
    BrowserBackIcon,
    ChevronDownIcon,
    ExcelFileIcon,
    PdfFileIcon,
    TriangleExclamationIcon,
} from "userlove-icons";

const headerTitle = "Userlove | {{title}}";

function replaceTextWithProps(text, props) {
    const pattern = /{{\s*(.*?)\s*}}/g;
    return text.replace(pattern, (_, key) => props[key.trim()]);
}

const Wrapper = (props) => {
    const { isLoading, children } = props;
    return isLoading ? (
        <LinearProgressLoader />
    ) : (
        <>
            <Helmet>
                <title>{replaceTextWithProps(headerTitle, props)}</title>
            </Helmet>
            {children}
        </>
    );
};

const GroupButton = ({ onClick = () => {}, buttonName = "", onExcelExport, onPdfExport }) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const anchorRef = useRef(null);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (anchorRef?.current !== e.target && !anchorRef?.current?.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);
    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef}>
                <Button onClick={onClick}>{buttonName}</Button>
                <Button style={{ padding: "0px" }}>
                    <ChevronDownIcon onClick={handleToggle} fontSize={22} />
                </Button>
            </ButtonGroup>
            <Popper
                className={styles["gs-export-popover"]}
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                placement="bottom-end"
                sx={{ zIndex: 9999999 }}
            >
                <div className={styles["gs-button-action-wrapper"]}>
                    {onExcelExport && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<ExcelFileIcon />}
                                className={`gs-secondary-other-ghost-button ${styles["gs-export-button"]} extraLarge-button`}
                                onClick={onExcelExport}
                            >
                                Export As Excel
                            </Button>
                        </div>
                    )}
                    {onPdfExport && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<PdfFileIcon className="secondary-light-icon" />}
                                className={`gs-secondary-other-ghost-button ${styles["gs-export-button"]} extraLarge-button`}
                                onClick={onPdfExport}
                            >
                                Export As PDF
                            </Button>
                        </div>
                    )}
                </div>
            </Popper>
        </>
    );
};

export const CustomExportButton = ({
    onPdfExport,
    onExcelExport,
    disabled = false,
    className,
    loading,
}) => {
    const [anchorEl, setAnchorEl] = useState(false);

    const handleClose = () => {
        setAnchorEl(false);
    };

    const handleClick = (e) => {
        e.stopPropagation();
        setAnchorEl((prevOpen) => !prevOpen);
    };

    const ref = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (ref?.current !== e.target && !ref?.current?.contains(e.target)) {
                setAnchorEl(false);
            }
        };
        document.addEventListener("click", handleOutsideClick);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <div ref={ref} className={className}>
            <Button
                className="gs-primary-button"
                disabled={loading || disabled}
                aria-label="settings"
                style={{ gap: "0px" }}
                onClick={handleClick}
            >
                Export{" "}
            </Button>

            <Popper
                className={styles["gs-export-popover"]}
                open={anchorEl}
                anchorEl={ref.current}
                onClose={handleClose}
                placement="bottom-end"
                sx={{ zIndex: 9999999 }}
            >
                <div className={styles["gs-button-action-wrapper"]}>
                    {onExcelExport && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<ExcelFileIcon />}
                                className={`gs-secondary-other-ghost-button ${styles["gs-export-button"]} extraLarge-button`}
                                onClick={onExcelExport}
                            >
                                Export As Excel
                            </Button>
                        </div>
                    )}
                    {onPdfExport && (
                        <div className={styles["gs-button-wrapper"]}>
                            <Button
                                startIcon={<PdfFileIcon className="secondary-light-icon" />}
                                className={`gs-secondary-other-ghost-button ${styles["gs-export-button"]} extraLarge-button`}
                                onClick={onPdfExport}
                            >
                                Export As PDF
                            </Button>
                        </div>
                    )}
                </div>
            </Popper>
        </div>
    );
};

export const ContentWrapper = ({
    children,
    title,
    sideButton,
    description,
    dummydata,
    className,
    onSearch = null,
    onClick,
    isLoading = false,
    isBackButton = false,
    onBackButtonClick,
    groupButton,
    customExportButton,
}) => {
    return (
        <Wrapper isLoading={isLoading} title={title}>
            <div className={`${styles["gs-content-wrapper-container"]} ${className || " "}`}>
                <div className={styles["gs-content-wrapper-container-header"]}>
                    <div className={styles["gs-content-wrapper-container-header-title"]}>
                        <Stack direction="row" gap="5px">
                            {isBackButton && (
                                <IconButton
                                    sx={{
                                        border: "solid 1px transparent",
                                        borderRaduis: "50%",
                                        height: "40px",
                                        width: "40px",
                                    }}
                                    onClick={onBackButtonClick}
                                >
                                    <BrowserBackIcon />
                                </IconButton>
                            )}
                            <Stack direction="column">
                                <div
                                    className={styles["gs-content-wrapper-container-title"]}
                                    onClick={onClick}
                                >
                                    {title}
                                    <div
                                        className={
                                            styles["gs-content-wrapper-container-description"]
                                        }
                                    >
                                        {description}
                                    </div>
                                </div>
                            </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {onSearch ? (
                                <SearchInput
                                    className={styles["gs-navbar-search"]}
                                    onSearch={onSearch}
                                />
                            ) : null}
                            <div
                                className={styles["gs-content-wrapper-container-side-button"]}
                                style={{ display: "flex", gap: "15px" }}
                            >
                                {sideButton}
                                {groupButton && <GroupButton {...groupButton} />}
                                {customExportButton && (
                                    <CustomExportButton {...customExportButton} />
                                )}
                            </div>
                        </Stack>
                    </div>
                    {!!dummydata && (
                        <div className={styles["gs-content-wrapper-container-dummydata"]}>
                            <TriangleExclamationIcon fontSize={18} />
                            {dummydata}
                        </div>
                    )}
                </div>
                <div className={styles["gs-content-wrapper-container-children"]}>{children}</div>
            </div>
        </Wrapper>
    );
};
