import { Button, Dialog, DialogContent, IconButton, Typography } from "shared/Components";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ReactLoading from "react-loading";
import { DeleteIcon } from "userlove-icons";
import styles from "./DeletePopup.module.css";

export const DeletePopup = ({
    open,
    onClose,
    text = "Do you really want to delete this record? This process cannot be undone",
    showCancelButton = true,
    cancelButtonText = "Cancel",
    confirmButtonText = "Yes, delete it!",
    title = "Confirm delete!",
    onCancel,
    onConfirm,
    loading,
    Icon = DeleteIcon,
    width = "130px",
}) => {
    return (
        <Dialog
            disableEscapeKeyDown
            classes={{
                root: styles["gs-security-signout-modal"],
                paper: styles.alert,
            }}
            open={open}
            onClose={(e, reason) => {
                if (reason === "backdropClick") return;
                onClose(e);
            }}
        >
            <DialogContent
                classes={{
                    root: styles.content,
                }}
            >
                <div className={styles.header}>
                    <Typography color="var(--secondary-color)" fontSize="15px">
                        {title}
                    </Typography>
                    {onClose && (
                        <IconButton disabled={loading} className={styles.close} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </div>
                <div className={styles.body}>
                    <div className={styles.icon}>
                        <Icon />
                    </div>
                    <Typography
                        color="var(--secondary-bk-color)"
                        fontSize="14px"
                        paddingLeft="10px"
                    >
                        {text}
                    </Typography>
                </div>
                <div className={styles.footer}>
                    <Button
                        onClick={onConfirm}
                        className={`${styles.btn} ${styles["confirm-btn"]}`}
                        disabled={loading}
                        sx={{
                            width: width,
                        }}
                    >
                        {loading ? (
                            <ReactLoading
                                className="gs-enable-disable-loader-bubble-genrate-otp"
                                type={"bubbles"}
                                color={"var(--white-color)"}
                            />
                        ) : (
                            confirmButtonText
                        )}
                    </Button>
                    {showCancelButton && (
                        <Button
                            onClick={onCancel}
                            className={`${styles.btn} ${styles["cancel-btn"]}`}
                            disabled={loading}
                        >
                            {cancelButtonText}
                        </Button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DeletePopup;
