import { lazy } from "react";

//*----------------------------- AUTHENTICATION MODULES -----------------------------//
import Login from "./Authentication/Login/Login";
import Registration from "./Authentication/Registration/Registration";
import ForgotPassword from "./Authentication/Login/ForgotPassword";

const OtpLogin = lazy(() =>
    import(/* webpackChunkName: "OtpLogin" */ "./Authentication/Login/OtpLogin"),
);
const TwoFAVerification = lazy(() =>
    import(/* webpackChunkName: "TwoFAVerification" */ "./Authentication/Login/TwofaVarification"),
);
const ResetPassword = lazy(() =>
    import(/* webpackChunkName: "ResetPassword" */ "./Authentication/Login/ResetPassword"),
);
const SetPassword = lazy(() =>
    import(/* webpackChunkName: "ResetPassword" */ "./Authentication/Login/SetPassword"),
);
const OtpConformation = lazy(() =>
    import(/* webpackChunkName: "OtpConformation" */ "./Authentication/Login/OtpConformation"),
);
const InvitationLink = lazy(() =>
    import(/* webpackChunkName: "OtpConformation" */ "./Authentication/Login/InvitationLink"),
);
const RegisterEmailVerify = lazy(() =>
    import(
        /* webpackChunkName: "OtpConformation" */ "./Authentication/Registration/RegisterEmailVerify"
    ),
);
const BookDemoForm = lazy(() =>
    import(/* webpackChunkName: "ResetPassword" */ "./Authentication/Login/BookDemoForm"),
);
const TermsService = lazy(() =>
    import(/* webpackChunkName: "Termsofservice" */ "./Authentication/Login/TermsService"),
);
const PrivacyPolicy = lazy(() =>
    import(/* webpackChunkName: "Privacypolicy" */ "./Authentication/Login/PrivacyPolicy"),
);
//*----------------------------- DASHBOARD MODULES -----------------------------//

const Dashboard = lazy(() =>
    import(/*webpackChunkName: "Dashboard"*/ "./apps/Dashboard/Dashboard"),
);
//*----------------------------- SETTINGS MODULES -----------------------------//
const GeneralSettings = lazy(() =>
    import(/* webpackChunkName: "General Settings" */ "./settings/GeneralSettings/GeneralSettings"),
);
const BusinessSettings = lazy(() =>
    import(
        /* webpackChunkName: "Business Settings" */ "./settings/BusinessSettings/BusinessSettings"
    ),
);
const TenantSettings = lazy(() =>
    import(/* webpackChunkName: "Tenant Settings" */ "./settings/TenantSetting/TenantSetting"),
);
const EmailTemplates = lazy(() =>
    import(/* webpackChunkName: "Email Templates" */ "./settings/EmailTemplates/EmailTemplates"),
);
const EmailTemplatesCategory = lazy(() =>
    import(
        /* webpackChunkName: "Email Templates Category" */ "./settings/EmailTemplatesCategory/EmailTemplatesCategory"
    ),
);
const EmailTemplatesKey = lazy(() =>
    import(
        /* webpackChunkName: "Email Templates Key" */ "./settings/EmailTemplatesKey/EmailTemplatesKey"
    ),
);
const SMTPSettings = lazy(() =>
    import(/* webpackChunkName: "SMTP Settings" */ "./settings/SMTPSettings/SMTPSettings"),
);
const NotificationSettings = lazy(() =>
    import(
        /* webpackChunkName: "Notification Settings" */ "./settings/NotificationSettings/NotificationSettings"
    ),
);
const Currencies = lazy(() =>
    import(/* webpackChunkName: "Currencies" */ "./settings/Currencies/Currencies"),
);

const Payments = lazy(() =>
    import(/* webpackChunkName: "Payments" */ "./settings/Payments/Payments"),
);

const AuthenticationServices = lazy(() =>
    import(/* webpackChunkName: "Auth Settings" */ "./settings/AuthServices/AuthServices"),
);
const Integration = lazy(() =>
    import(/* webpackChunkName: "integration" */ "./settings/Integration/Integration"),
);
const SMSServices = lazy(() =>
    import(/* webpackChunkName: "SMS Services" */ "./settings/SMSServices/SMSServices"),
);
const LanguagesSettings = lazy(() =>
    import(
        /* webpackChunkName: "Language Settings" */ "./settings/LanguagesSettings/LanguagesSettings"
    ),
);
const Countries = lazy(() =>
    import(/* webpackChunkName: "Countries" */ "./settings/Countries/Countries"),
);
const States = lazy(() => import(/* webpackChunkName: "States" */ "./settings/States/States"));
const SystemCategory = lazy(() =>
    import(/* webpackChunkName: "Sandbox Category" */ "./settings/SystemCategory/SystemCategory"),
);
const GeoZone = lazy(() => import(/* webpackChunkName: "GeoZone" */ "./settings/GeoZone/GeoZone"));
const LocalVariables = lazy(() =>
    import(/* webpackChunkName: "Local Variables" */ "./settings/LocalVariables/LocalVariables"),
);
const DemoRequest = lazy(() =>
    import(/* webpackChunkName: "Demo Requests" */ "./settings/DemoRequest/DemoRequest"),
);

const RoleAccess = lazy(() =>
    import(/* webpackChunkName: "Role Access" */ "./settings/RoleAccess/RoleAccess"),
);

//*----------------------------- ORGANIZATIONS MODULES -----------------------------//
const EarlyAccessRequest = lazy(() =>
    import(
        /* webpackChunkName: "Early Access Request" */ "./organizations/EarlyAccessRequest/EarlyAccessRequest"
    ),
);

const Organization = lazy(() =>
    import(/* webpackChunkName: "Organization" */ "./organizations/Organization/Organization"),
);
const Users = lazy(() => import(/* webpackChunkName: "Users" */ "./organizations/Users/Users"));
// const Roles = lazy(() => import(/* webpackChunkName: "Roles" */ "./organizations/Roles/Roles"));
const Roles = lazy(() => import(/* webpackChunkName: "Roles" */ "./settings/Roles/Roles"));

const AddRolePermission = lazy(() =>
    import(
        /* webpackChunkName: "AddRolePermission" */ "./settings/Roles/components/AddRolePermission"
    ),
);

const SystemUsers = lazy(() =>
    import(/* webpackChunkName: "SystemUsers" */ "./settings/SystemUsers/SystemUsers"),
);

const Quotation = lazy(() =>
    import(/* webpackChunkName: "Quotation" */ "./organizations/Quotation/Quotation"),
);

//*----------------------------- APPS MODULES -----------------------------//
const AppList = lazy(() =>
    import(/* webpackChunkName: "AppList" */ "./apps/Applications/Application"),
);

const AppFeatures = lazy(() =>
    import(
        /* webpackChunkName: "Features" */ "./apps/Applications/ApplicationsFeatures/AppFeaturesList"
    ),
);

const SubFeatures = lazy(() =>
    import(/* webpackChunkName: "SubFeatures" */ "./apps/Applications/AppSubFeatures/SubFeatures"),
);
// const FeatureType = lazy(() =>
//   import(/* webpackChunkName: "FeatureType" */ "./apps/FeatureType/FeatureType")
// );
// const InputTypes = lazy(() =>
//   import(/* webpackChunkName: "InputTypes" */ "./apps/InputTypes/InputTypes")
// );
// const LicenseType = lazy(() =>
//   import(/* webpackChunkName: "LicenseType" */ "./apps/LicenseType/LicenseType")
// );

//*----------------------------- TEMPLATES MODULES -----------------------------//

const ProductTourType = lazy(() =>
    import(/* webpackChunkName: "ProductTourType" */ "./templates/ProductTourType/ProductTourType"),
);

const ProductTourTemplate = lazy(() =>
    import(
        /* webpackChunkName: "ProductTourTemplate" */ "./templates/ProductTourTemplate/ProductTourTemplate"
    ),
);

const NPSType = lazy(() => import(/* webpackChunkName: "NPSType" */ "./templates/NPSType/NPSType"));

const SurveyElementsType = lazy(() =>
    import(
        /* webpackChunkName: "SurveyElementsType" */ "./templates/SurveyElementsType/SurveyElementsType"
    ),
);

const DefaultSurvey = lazy(() =>
    import(/* webpackChunkName: "DefaultSurvey" */ "./templates/DefaultSurvey/DefaultSurvey"),
);

//*----------------------------- PACKAGE MODULES -----------------------------//

const Packages = lazy(() =>
    import(/* webpackChunkName: "Package list" */ "./plans/Packages/Packages"),
);

const BasePrice = lazy(() =>
    import(/* webpackChunkName: "BasePrice" */ "./plans/BasePrice/BasePrice"),
);

const Discount = lazy(() => import(/* webpackChunkName: "Discount" */ "./plans/Discount/Discount"));

const Coupon = lazy(() => import(/* webpackChunkName: "Coupon" */ "./plans/Coupon/Coupon"));

const MAUsRange = lazy(() =>
    import(/*webpackChunkName: "MAUsRange" */ "./plans/MAUsRange/MAUsRange"),
);

//*----------------------------- EARLY ACCESS MODULES -----------------------------//

const EarlyAccess = lazy(() =>
    import(/* webpackChunkName: "EarlyAccessRequest" */ "./EarlyAccessRequest/EarlyAccessRequest"),
);

export {
    Login,
    Registration,
    ForgotPassword,
    OtpLogin,
    TwoFAVerification,
    ResetPassword,
    SetPassword,
    Dashboard,
    OtpConformation,
    InvitationLink,
    RegisterEmailVerify,
    BookDemoForm,
    TermsService,
    PrivacyPolicy,
    GeneralSettings,
    BusinessSettings,
    EmailTemplates,
    EmailTemplatesCategory,
    EmailTemplatesKey,
    SMTPSettings,
    NotificationSettings,
    Currencies,
    Payments,
    Integration,
    AuthenticationServices,
    SMSServices,
    LanguagesSettings,
    Countries,
    States,
    SystemCategory,
    GeoZone,
    RoleAccess,
    EarlyAccessRequest,
    LocalVariables,
    DemoRequest,
    Organization,
    Users,
    Roles,
    AddRolePermission,
    SystemUsers,
    AppList,
    ProductTourType,
    ProductTourTemplate,
    NPSType,
    AppFeatures,
    SubFeatures,
    // FeatureType,
    // InputTypes,
    // LicenseType,
    SurveyElementsType,
    DefaultSurvey,
    Quotation,
    Packages,
    Discount,
    Coupon,
    MAUsRange,
    BasePrice,
    EarlyAccess,
    TenantSettings,
};
