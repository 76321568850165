import { Popover } from "@mui/material";
import { LocalizationProvider, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import React, { useState } from "react";
import { Button } from "..";
import styles from "./DateRangePicker.module.css";
import { getDateRangeText } from "shared/utils";

const dayNames = {
    su: "SUN",
    mo: "MON",
    tu: "TUE",
    we: "WED",
    th: "THU",
    fr: "FRI",
    sa: "SAT",
};
const DateRangePicker = ({
    value = [null, null],
    onRangeChange = () => {},
    onTypeChange,
    selected,
    dateRanges,
    isCustom,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const rangeText = getDateRangeText(...value);
    const dayOfWeekFormatter = (day) => dayNames[day.substring(0, 2).toLowerCase()]?.toUpperCase();

    return (
        <>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                className={styles["popover"]}
            >
                <div className={styles["date-picker"]}>
                    <div className={styles["date-picker-side-bar"]}>
                        {!!dateRanges.length &&
                            dateRanges?.map(({ id, label, ...rest }) => {
                                return (
                                    <>
                                        <div
                                            onClick={() => {
                                                onTypeChange({ id, label, ...rest });
                                                setAnchorEl(null);
                                            }}
                                            key={id}
                                            className={styles["popover-menu"]}
                                        >
                                            {label}
                                        </div>
                                    </>
                                );
                            })}
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StaticDateRangePicker
                            showToolbar={false}
                            displayStaticWrapperAs="desktop"
                            calendars={1}
                            value={value}
                            disableFuture
                            onChange={onRangeChange}
                            renderQuickSelection={() => null}
                            className={styles["picker"]}
                            dayOfWeekFormatter={dayOfWeekFormatter}
                        ></StaticDateRangePicker>
                    </LocalizationProvider>
                </div>
            </Popover>
            <Button
                disableRipple={true}
                className={styles["date-picker-button"]}
                onClick={handleClick}
            >
                <span className={styles["custom-label"]}>
                    {isCustom ? "CustomRange" : selected.label}
                </span>
                {!!rangeText && <span className={styles["range-date"]}>{rangeText}</span>}
            </Button>
        </>
    );
};

export default DateRangePicker;
