/* eslint-disable no-unused-vars */

import React, { createContext, useContext } from "react";
import MUIDrawer from "@mui/material/Drawer";
import { Box, Divider, Typography, Stack, IconButton, Tooltip } from "@mui/material";
import styles from "./Drawer.module.css";

import { XMarkIcon } from "userlove-icons";

const drawerCtx = createContext();

const Header = ({ title, children, description = null }) => {
    const { onClose } = useContext(drawerCtx);

    return (
        <>
            <Box className={styles.header}>
                <Stack spacing={0.5}>
                    <Typography variant="h4" fontSize={16} fontWeight={500}>
                        {title}
                    </Typography>
                    {description ? (
                        <Typography
                            className={styles["description"]}
                            variant="h4"
                            fontSize={14}
                            fontWeight={500}
                        >
                            {description}
                        </Typography>
                    ) : null}
                </Stack>
                <div className={styles["buttons-wrapper"]}>
                    {children}
                    <Tooltip
                        classes={{ popper: "gs-tooltip" }}
                        arrow
                        title={"Close"}
                        placement="bottom"
                    >
                        <IconButton className={styles["close-button"]} onClick={onClose}>
                            <XMarkIcon className="close-icon" />
                        </IconButton>
                    </Tooltip>
                </div>
            </Box>
            <Divider />
        </>
    );
};

const Body = ({ children, className }) => {
    return <Box className={`${styles.body} ${className}`}>{children}</Box>;
};

const Drawer = ({
    anchor = "right",
    open,
    onClose,
    width = 786,
    showCloseIcon = true,
    children,
}) => {
    return (
        <drawerCtx.Provider
            value={{
                onClose,
                showCloseIcon,
            }}
        >
            <MUIDrawer
                PaperProps={{
                    style: {
                        maxWidth: width,
                        width: "100%",
                        color: "#000",
                    },
                }}
                anchor={anchor}
                open={open}
                onClose={onClose}
            >
                <Box role="presentation" className={styles.wrapper}>
                    {children}
                </Box>
            </MUIDrawer>
        </drawerCtx.Provider>
    );
};

Drawer.Header = Header;
Drawer.Body = Body;

export { Drawer };
