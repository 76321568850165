import React, { useState } from "react";
import { CircleHelpIcon, HeadphoneIcon } from "userlove-icons";
import styles from "./Navbar.module.css";
import { Button, Popover, Stack } from "..";
export const HelpMenu = () => {
    const [anchorEl, setAnchorEl] = useState();
    const openPopover = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const closePopover = () => setAnchorEl(null);
    return (
        <>
            <button className={styles["gs-theme-button"]} onClick={openPopover}>
                <CircleHelpIcon className="primary-icon" />
                <span>Get help</span>
            </button>
            <Popover
                style={{ width: "200px" }}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={closePopover}
                className={styles["gs-popover"]}
                anchorOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Stack>
                    <div className={styles["gs-button-wrapper"]}>
                        <Button
                            className={`gs-secondary-other-ghost-button ${styles["gs-action-button"]} ${styles["help-menu"]} extraLarge-button`}
                            onClick={() => window.open("https://docs.userlove.io/", "_blank")}
                            startIcon={<HeadphoneIcon className="secondary-light-icon" />}
                        >
                            Help Center
                        </Button>
                    </div>
                    {/* <div className={styles["gs-button-wrapper"]}>
            <Button
              className={`gs-secondary-other-ghost-button ${styles["gs-action-button"]} ${styles["help-menu"]} extraLarge-button`}
              onClick={() =>
                window.open("https://www.userlove.io/contact-support", "_blank")
              }
              startIcon={<PhoneVolumeIcon className="secondary-light-icon" />}
            >
              Contact Support
            </Button>
          </div> */}
                </Stack>
            </Popover>
        </>
    );
};
