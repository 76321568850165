import React, { useState } from "react";
import { getCookie, setCookie } from "../../shared/Assets/JS/Cookies";
import axios from "axios";
import { useCommonFunction } from "./useCommonFunction";
import { Routes } from "Routes/Routes";
import { useNavigate } from "react-router-dom";
import { CircleDollarIcon, GearIcon, UserCheckIcon } from "userlove-icons";

export const useNavbar = () => {
    const { notify, AxiosUrl } = useCommonFunction();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        if (anchorEl) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const buttonObj = [
        {
            id: 1,
            text: "Payments",
            icons: <CircleDollarIcon className="secondary-light-icon" />,
            routes1: "/settings/payments",
        },
        {
            id: 2,
            text: "Role access",
            icons: <UserCheckIcon className="secondary-light-icon" />,
            routes1: "/settings/role-access",
        },
        {
            id: 3,
            text: "Settings",
            icons: <GearIcon className="secondary-light-icon" />,
            routes1: "/settings",
        },
    ];
    const getAccountDetail = [
        {
            id: 1,
            account_name: "Jyoti chauhan",
            logo: "/image/svg/avtar1.svg",
            role: "System admin",
        },
    ];
    const getAccount = [
        {
            id: 1,
            account_name: "Casepoint LLC",
            logo: "/image/svg/avtar1.svg",
            role: "System admin",
        },
        {
            id: 2,
            account_name: "Gainserv Tech LLP",
            logo: "/image/svg/avtar1.svg",
            role: "Supervisor",
        },
    ];

    // const {
    //   data: { getAllAssignedAccounts: { data: rows = [] } } = {
    //     getAllAssignedAccounts: {},
    //   },
    // } = useQuery(GET_ALL_ASSIGNED_ACCOUNT, {
    //   fetchPolicy: "network-only",
    //   onCompleted: (item) => {},

    //   // Used for subsequent executions
    // });
    //*---------------------------------- GET COMPANY DATA  ---------------------------------*/
    // const { data: getAccountByTokenAccountId } = useQuery(
    //   GET_COMPANY_DETAILS_DATA,
    //   {
    //     fetchPolicy: "network-only",
    //     onCompleted: (items) => {},
    //     onError: ({ networkError }) => {
    //       // HandleGraphqlError(networkError.result);
    //       // notifyDanger(networkError.result.message);
    //     },
    //   }
    // );
    const access_token = getCookie("admin_access_token");
    const refresh_token = getCookie("admin_refresh_token");

    // const handleLogoutSubmit = async () => {
    //     await axios
    //         .delete(`${AxiosUrl}/user/logout`, {
    //             headers: {
    //                 Authorization: access_token,
    //             },
    //         })
    //         .then(() => {
    //             // navigate("/");
    //             sessionStorage.clear();
    //             let currentHost = process.env.REACT_APP_HOST;
    //             currentHost = currentHost.substring(14);
    //             window.location.host = currentHost;
    //             window.location.reload(true);
    //         });
    // };

    // const {
    //   data: { getAllAssignedRoles: { data: roles = [] } } = {
    //     getAllAssignedRoles: [],
    //   },

    //   loading: getAllAssignedRolesLoding,
    // } = useQuery(GET_ALL_ASSIGNED_ROLES, {
    //   onCompleted: (item) => {
    //     // setGetAllAssignRoleName(item?.getAllAssignedRoles?.data);
    //   },
    // });
    // const [updateTheme, { loading: updateThemeLoading }] = useMutation(
    //   SET_USER_THEME,
    //   {
    //     onCompleted: (item) => {
    //       if (item.updateUserTheme.status) {
    //         window.localStorage.setItem(
    //           "theme",
    //           `${item?.updateUserTheme?.data?.theme}`
    //         );
    //       }
    //     },
    //   }
    // );

    const handleRoleChange = async (e) => {
        await axios
            .post(
                `${AxiosUrl}/switch-role`,
                { role_id: e.target.value, refresh_token: refresh_token },
                {
                    headers: {
                        Authorization: access_token,
                        "sub-domain": getCookie("admin_sub_domain"),
                    },
                },
                {
                    withCredentials: true,
                },
            )
            .then((res) => {
                setCookie("admin_access_token", res.data.data.access_token, 10000);
                setCookie("admin_refresh_token", res.data.data.refresh_token, 10000);
                window.location.reload(true);
            });
    };

    const handleSwitchAccount = async (item) => {
        try {
            await axios
                .post(
                    `${AxiosUrl}/switch-account`,

                    { account_id: item, refresh_token: refresh_token },

                    {
                        headers: {
                            Authorization: access_token,
                            "sub-domain": getCookie("admin_sub_domain"),
                        },
                    },
                    {
                        withCredentials: true,
                    },
                )
                .then((response) => {
                    if (response?.data?.status) {
                        setCookie("admin_sub_domain", response.data.data.sub_domain, 100);

                        setCookie("admin_access_token", response.data.data.access_token, 100);
                        setCookie("admin_refresh_token", response.data.data.refresh_token, 100);
                        setCookie("admin-user-login", "true", 100);

                        setCookie(
                            "admin-user-data",
                            JSON.stringify({
                                name: `${response.data.data.first_name} ${response.data.data.last_name}`,
                                mail: response.data.data.email_id,
                                photo: response.data.data.profile_picture,
                                id: response.data.data.user_id,

                                country_id: response.data.data.country_id,
                            }),
                            2000,
                        );
                        response.data.data.is_navigate
                            ? navigate(Routes.User.Settings.Profile)
                            : navigate(Routes.User.Dashboard);
                        let currentHost = process.env.REACT_APP_HOST;
                        // currentHost = currentHost.replace("%%subdomain%%", getCookie("sub_domain"));
                        currentHost = currentHost.replace("admin", getCookie("admin_sub_domain"));
                        window.location.host = currentHost;
                        // window.location.reload(true);
                    } else {
                        notify("error", response.message);
                    }
                })
                .catch((error) => {
                    notify("error", error?.response?.data?.message);
                });
        } catch (error) {
            notify("error", error.response.message);
        }
    };
    return {
        // updateTheme,
        anchorEl,
        setAnchorEl,
        handleClick,
        handleClose,
        buttonObj,
        getAccount,
        getAccountDetail,
        // rows: rows || [],
        access_token,

        refresh_token,
        // handleLogoutSubmit,
        // roles: roles || null,
        handleRoleChange,
        handleSwitchAccount,
        // getAccountByTokenAccountId,
    };
};
