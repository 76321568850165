import React from "react";
import { MUITextField, IconButton } from "../";
import styles from "./SearchInput.module.css";
import { debounce } from "lodash";
import { CircleXMarkIcon, MagnifyingGlassIcon } from "userlove-icons";

export const SearchInput = ({
    fullWidth = true,
    placeholder = "Search...",
    value,
    // onChange,
    // medium, small
    size: textFieldSize = "medium",
    InputProps = {},
    onClear,
    // cancelIconProps = { size: "medium" },
    className,
    searchIconSize = 18,
    onSearch,
    ...rest
}) => {
    const debouncedSearch = debounce((e) => onSearch(e), 1000);
    return (
        <MUITextField
            fullWidth={fullWidth}
            className={`${styles["search-input"]} ${className || " "}`}
            placeholder={placeholder}
            value={value}
            size={textFieldSize}
            onChange={debouncedSearch}
            InputProps={{
                ...InputProps,
                startAdornment: (
                    // <SearchIcon width={searchIconSize} height={searchIconSize} stroke={"#eee"} />
                    <MagnifyingGlassIcon
                        width={16}
                        height={searchIconSize}
                        className="light-grey-icon"
                    />
                ),
                ...(!!value && {
                    endAdornment: (
                        <IconButton
                            onClick={onClear}
                            className={styles["close-icon"]}
                            disableFocusRipple
                        >
                            <CircleXMarkIcon height={14} width={14} />
                        </IconButton>
                    ),
                }),
            }}
            {...rest}
        />
    );
};
